

.navSeccions {
  margin: 0 14px;
  font-family: "Jost", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: white;
  transition: all .5s;
  text-decoration: none;
}

.navSeccions.active {
  color: white;
  font-size: 18px;
  border-radius: 5px;

}

.navSeccions:hover {
  font-size: 18px;
}

.ulSeccion {
  padding: 14px 0;
  background: linear-gradient(to top, #a70000, #670000);
}

.ulSeccion.dark-mode{
  background:  #420000;

}

.divSeccion {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.divSeccion a{
  color: white !important;
}

@media screen and (max-width: 800px) {
  .navSeccions{
    font-size: 13px;
  }
  .navSeccions.active {
    font-size: 14px;

  }

  .navSeccions:hover {
    color: white;
    font-size: 14px;
  }
}


@media screen and (max-width: 650px) {

  .navSeccions {
    margin: 0 10px;
    font-size: 15px;
    font-weight: 800;
  }

  .ocasionesSeccion {
    margin: 0 10px;
    font-size: 15px;
    font-weight: 800;
  }

  .ulSeccion {
    padding-bottom: 0px;
    margin: 5px 0;

  }

}

@media screen and (max-width: 450px) {

  .navSeccions {
    margin: 0 10px;
    font-size: 10px;
    font-weight: 800;
  }
  
  .ocasionesSeccion {
    margin: 0 10px;
    font-size: 15px;
    font-weight: 800;
  }

  .ulSeccion {
    padding-bottom: 0px;
    margin: 5px 0;

  }

}