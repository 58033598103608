.whatsapp-mobile-container {
    display: none;
}


@media screen and (max-width: 450px) {


    .whatsapp-mobile-container {
        /* position: fixed;
        bottom: 20px;
        right: 20px; */
        display: flex;
        /* flex-direction: column;
        align-items: flex-end;
        z-index: 1000;
        transition: transform 0.3s ease; */
    }

    .whatsapp-mobile-button {
        background-color: #25d366;
        color: #fff;
        border: none;
        border-radius: 50%;
        font-size: 24px;
        padding: 10px;
        cursor: pointer;
    }

    .whatsapp-mobile-content {
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 5px;
        margin-top: 10px;
    }

    .openIcon {
        transform: translateX(-150px);
    }

    .closeIcon {
        transform: translateX(0);
    }
}