.directions-div{
    background: linear-gradient(to bottom, white, rgba(192, 192, 192, 0.623) 25%);
    border-radius: 10px;
    border: 1px solid grey;
    font-family: "Jost", sans-serif;
      margin: 30px 20px;
    padding: 0 10px;

}

.directions-div h1{
    background: rgb(106, 0, 0);
    padding: 20px;
    margin-top: 0px;
    border-radius: 10px;
    color: rgb(255, 255, 255);
}

.envio-direction-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.li-directs{
    margin: 7px 0;
    text-align: left;
}

.li-directs a{
    font-size: 20px;
    font-weight: 800;
    font-family: "Jost", sans-serif;
    color: darkred!important;
      transition: all .5s;
    text-decoration: none;
}

.li-directs a:hover{
    font-size: 21px;
    color: rgb(189, 0, 0);
  
}