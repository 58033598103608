.divHomeBanner {
  position: relative;
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
}

.image {
  position: relative;
  z-index: 0;
}

.divTypography {
  position: relative;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  top: 39vh;
  align-items: center;
}

.typography {

  color: rgb(0, 0, 0);

}

@media screen and (max-width: 650px) {

.divHomeBanner{
  padding-top: 50px;
}
  



}