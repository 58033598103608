.div-modificar-user{
    padding-top: 104px;
}


.updateProfile-div{
    color: rgb(0, 0, 0) !important;
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    background: #ffffff;
    margin: 15px;
    border-radius: 5px;
    font-family: "Jost", sans-serif;
    font-size: 17px;
    align-items: center;
}

.updateProfile-div h2{
    color: rgb(0, 0, 0)!important;
    font-family: "Jost", sans-serif;
    font-size: 25px;
    margin: 10px;
}

.form-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: min-content;
    align-items: center;
    align-self: center;
}

.input-group{
    width: 100%;

}

.form-registro label{
    color: rgba(0, 0, 0, 0.461);
}

