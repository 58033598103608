.div-addCost{    
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div-directions {
    color: black;
    margin-top: 20px;
}

.table-direct {
    border: 1px solid darkred;
    border-radius: 5px;
    width: 100%;
    color: black;
}

.table-direct td {
    border: 1px solid black;
    border-radius: 1px;
    font-family: "Jost", sans-serif;
    font-size: 17px;
    font-weight: 800;
}

.table-direct th {
    border: 1px solid silver;
    border-radius: 1px;
    font-family: "Jost", sans-serif;
    font-size: 20px;
    padding: 10px 0;
    font-weight: 800;
    color: white;
    background: linear-gradient(to bottom, #2c0000, #a00303);
    transition: all .25s ease;
}

.table-direct tr{
    background:linear-gradient(to bottom , white, rgb(241, 241, 241));

}
.table-direct tr:hover{
    background: rgb(255, 255, 255);
}

.btn-delete{
    background: rgb(133, 0, 0);
    color: white;
    padding: 5px 8px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    margin: 2px 10px;
    border: solid red 1px;
    border-radius: 3px;
    transition: all .25s ease-in;
}

.btn-delete:hover{
    background: rgb(211, 3, 3);
}

.btn-edit{
    background: #225eb2;
    color: white;
    padding: 5px 8px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    margin: 2px 10px;
    border: solid rgb(0, 13, 255) 1px;
    border-radius: 3px;
    transition: all .25s ease-in;
}

.btn-edit:hover{
    background: blue;
}

.btn-save{
    background: rgb(5, 131, 7);
    color: white;
    padding: 2%;
    font-family: "Jost", sans-serif;
    margin: 2px 10px;
    border: solid rgb(0, 255, 34) 1px;
    border-radius: 3px;
    transition: all .25s ease-in;
}

.btn-save:hover{
    background: rgb(8, 96, 8);

}