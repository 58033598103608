.div-price {
  display: flex;
  font-weight: 700;
  flex-direction: row;
}


.div-add-edit-prods {
  padding-top: 40px;
  color: white;
}

.div-add-edit-prods h1 {
  font-family: "Jost", sans-serif;
  background: #3d3d3df8;
  border-radius: 3px;
  padding: 8px;
}

.div-addProd {
  border: 1.35px solid #670000;
  border-radius: 5px;
}

.title-edit-prods {
  font-family: "Jost", sans-serif;
  background: #3d3d3df8;
  border-radius: 3px;
  padding: 8px;
  color: white;
}

.form-addProd {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  text-align: justify;
  font-family: "Jost", sans-serif;
  background: linear-gradient(to bottom, white, #b4b4b4);
  color: black;
  padding: 30px;
  border-radius: 5px;

}

.form-addProd label {
  font-size: larger;
  font-weight: 600;
  margin: 8px 0;
  text-transform: uppercase;
      letter-spacing: 2px;
}

.form-addProd input,
textarea {
  border-radius: 8px;
  padding: 8px;
  width: 60%;
  margin: 0 0 15px;
}

.div-opt-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 20px;
  border: 1px solid white;
  background: linear-gradient(to bottom, white, #b4b4b4);
  border-radius: 7px;

}

.div-new-img-opt {
  margin: 10px 0
}

.div-opt {
  display: flex;
  flex-wrap: wrap;
  background: #670000;
  border-radius: 10px;
}

.add-prod-btn {
  margin: 20px;
  transition: all .5s;
}

.add-prod-btn:hover {
  background: rgb(148, 126, 0);
  color: white;
}

.div-table {
  padding: 15px;
}

.thead-table {
  background: rgb(0, 0, 0);
    font-family: "Jost", sans-serif;
    font-family: "Jost", sans-serif;
    font-family: "Jost", sans-serif;

}

.td-tbody {
  border: .8px solid black;
    font-family: "Jost", sans-serif;
    font-family: "Jost", sans-serif;
    font-family: "Jost", sans-serif;
}

.tr-tbody:hover {
  transition: background .20s ease-in;
  background: #670000;
}

.td-tbody-price {
  font-weight: 700;
  border: .8px solid black;
    font-family: "Jost", sans-serif;
  padding: 3px 10px;
}

.td-tbody-descr {
  font-size: small;
  border: .8px solid black;
  width: 25%;
    font-family: "Jost", sans-serif;
    font-family: "Jost", sans-serif;
}

.td-tbody-cats {
  font-size: medium;
  border: .8px solid black;
  width: 15%;
    font-family: "Jost", sans-serif;
    font-family: "Jost", sans-serif;
}

.td-tbody-btns{
  width: 30%;
  border: .8px solid black;
    font-family: "Jost", sans-serif;
    font-family: "Jost", sans-serif;
}

.btns-table {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.products-table {
  width: 100%;
  background: white;
  color: black;

}
.waiting-all-changes{
  color: black;
    font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

}

.btn-table-delete {
  margin: 5px;
  padding: 5px 8px;
  border-radius: 5px;
  background: darkred;
  color: white;
    font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  transition: background .4s;
}

.btn-table-delete:hover {
  background: rgb(214, 1, 1);
}

.btn-table-edit {
  margin: 5px;
  padding: 5px 8px;
  border-radius: 5px;
  background: rgba(0, 0, 219, 0.763);
  color: white;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  transition: background .4s;

}

.btn-table-edit:hover {
  background: rgb(23, 119, 255);
}

.div-products-table {
  width: 100%;
  border: 1px solid #670000;
  margin: 20px 0;
  border-radius: 5px;
  background: linear-gradient(to bottom, #e0e0e0, #383838);
}

.div-newOpt {
  display: flex;
  flex-direction: column;
  padding: 35px;
  background: linear-gradient(to bottom, white, #b4b4b4);
  color: black;
  border-radius: 5px;
  margin: 20px 5px;
  box-shadow: 0 0 11px 0px #797979;

}

.div-newOpt div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.div-newOpt p {
  margin: 0 15px 9px 0px;
  font-weight: 700;
  font-size: 22px;
  font-family: "Jost", sans-serif;
}

.div-newOpt input {
  margin: 9px 10px 20px 0px;
  padding: 8px;
  border-radius: 6px;
  width: 40%;
}

.div-massiveEdit {
  background: linear-gradient(to bottom, white, rgba(255, 236, 236, 0.94));
}

.div-massiveEdit form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.div-mapedit-massive {
  border: 1px solid grey;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.div-mapedit-massive label {
  margin: 0 5px 0 0;
  font-family: "Jost", sans-serif;
}

.div-edit-massive {
  width: 48%;
  border: 1px solid grey;
  margin: 5px;
  border-radius: 5px;
  background: linear-gradient(to bottom, white, #b9b9b952);
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.div-edit-massive h2 {
  font-family: "Jost", sans-serif;
}


.btn-confirm-massiveEdition {
  padding: 8px;
  background: rgb(7, 169, 7);
  color: white;
  border: 1px solid green;
  font-family: "Jost", sans-serif;
  font-size: large;
  border-radius: 5px;
  cursor: pointer;
  margin: 15px 0;
  transition: background .6s ease-out;
}

.btn-confirm-massiveEdition:hover {
  background: rgb(0, 94, 0);
}

.div-porcentaje {
  padding: 20px;
}

.div-porcentaje label {
  font-family: "Jost", sans-serif;
  margin: 0 10px;

}