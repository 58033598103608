.div-editCosts{
    color: white;
    margin-top: 20px;
}

.form-addCost{
    display: flex;
    flex-direction: column;
    background:white;
    margin: 20px 25px;
    padding: 30px;
    width: 45%;
    color: black;
    border-radius: 10px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}