.boton-abrircarrito {
    transition: transform 0.8s ease;
  }
  
  .expandir {
    animation: expandirAnimacion 0.8s ease;
  }
  
  @keyframes expandirAnimacion {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.3);
    }
  }

  .boton-abrircarrito {
    display: flex;
    padding: 0px 12px;
    font-size: 20px;
    cursor: pointer;
    z-index: 1000;
    text-decoration: none;
    color: #670000!important;
    background-color: white !important;
    box-shadow: none!important;
    transition: all .45s ease-in-out;
  }
  
  .boton-abrircarrito:hover {
    color: #a00303!important;
  }
  
  .boton-abrircarrito:active {
    background-color: #a00303;
    box-shadow: 0 0px #666;
  }
  .contadorCarrito {
    margin-right: 5%;
  }

  .boton-abrircarrito.dark-mode{
    box-shadow: none;
    color: white!important;
    background-color: transparent !important;
  }


  @media screen and (max-width: 650px) {
    
  .boton-abrircarrito {
    display: flex;
    padding: 0px 12px;
    font-size: 20px;
    cursor: pointer;
    z-index: 1000;
    text-decoration: none;
    color: #670000!important;
    background: transparent!important;
    /* border: 0.5px rgba(236, 90, 255, 0.579) solid; */
    /* border-radius: 5px; */
    box-shadow: 0px 4px 5px 1px #000000ad;
    transition: color .45s ease-in-out;
    position: fixed;
    right: 22px;
    top: 25px;
  }

    .boton-abrircarrito.dark-mode{
      color: white!important;
    }
  
  
  }  