.divAyudaCompras{
    color: white;
    font-family: "Jost", sans-serif;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 7px;
    margin-top: 30px;
}

.divPago{
    background: linear-gradient(to bottom, white, silver);
    color: black;
    border-radius: 10px;
    padding: 10px 30px;
    margin: 20px 20px;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
}
.divLinksAyuda{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;

}

.linkAyuda{
    color: #a00303;
    transition: color .5s ;
}
.linkAyuda:hover{
    color:   #a00303;
}


.divProceso{
    background: linear-gradient(to bottom, white, silver);
    color: black;
    border-radius: 10px;
    padding: 10px 30px;
    margin:0px 20px;
}
.proceso{
    background: #6e0202;
    padding: 20px;
    color:white;
    margin-top: 0px;
    border-radius: 10px;
}
.directions{
    display: flex;
    justify-content: center;
}