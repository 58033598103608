.div-orders{
    padding-top: 100px;
}

.orderDetails{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border: 1px solid #000;
    border-radius: 10px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px #000;
    width: 98%;
}

.orderDetails h5{
    text-decoration: underline;

}

.table-order{
    padding: 30px 45px;
}
@media screen and (max-width: 650px){
    .table-order{
        padding: 10px 15px;
    }
}