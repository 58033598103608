.text{
    color: white;
    padding: 20px;
}

.secondText{
    color: white;
    
}

.divInfo{
    padding: 30px 30px 70px;
}
.divPapers{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

#divInformacion{
    backdrop-filter: blur(15px)!important;
}

.paperMiniText{
    flex: 1;
    text-align: center;
    margin: 10px;
}

.divEmpresasAsociadas{
    width: 100%;
    
}
.empresas{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 10px 5px;
}
.linkEmpresas{
    cursor: pointer;
    text-decoration: none;
}

.linkEmpresas img{
    opacity: 1;
    transition: all .44s ease-in-out ;
}

.linkEmpresas img:hover{
    opacity: .85;
    width: 282px;
    height: 222px;
}

@media screen and (max-width: 650px) {

    .divInfo{
        padding: 30px 10px 70px;
        text-align: center;
    }
    .secondText{
        text-align: center;
        font-size: small;
    
    }
  }