.login-form{
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    border: 1px solid #670000;
    background: #ffffffe2;
    margin: 15px;
    border-radius: 5px;
    font-family: "Jost", sans-serif;
    font-size: 17px;
    margin-top: 80px;
}

.login-form a{
    text-decoration: none;
    color: #670000;
    font-size: large;
    transition: color .35s ease;
}
.login-form a:hover{
    color: red;
}

.div-passw{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}
.div-passw input{
    border: 1px solid #670000;
}

.form-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: min-content;
    align-items: center;
    align-self: center;
}

.input-group{
    width: 100%;

}

.input-group input{

    width: 350px;
}

.registrarse{
    padding: 40px 5px;
}

@media screen and (max-width: 650px) {

    .input-group input{
        width: 250px;
    }
}

