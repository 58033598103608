.topProductsDiv{
    flex: 1 1;
    max-width: 238px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 10px;
    color: black;
}