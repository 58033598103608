.products{
    padding-top: 0px;
}

.products-content{
 display: flex;
}

.products-list-container{
    padding-left: 2%;
    padding-right: 2%;
    flex: 8
}

.products-list-container .product-item{
    width: 240px;
    margin-left: 10px;
    margin-right: 10px;
    height: 100%;
}


@media screen and (max-width: 650px) {

    .div-prod-details-indx{
        margin-bottom: 5px;
    }
 
    .products-list-container .product-item {
        width: 175px;
        margin: 0 5px;
        flex: auto;
    }
    .product-item {
        font-size: small;
    }
    .prod-details-index {
        font-size: 16px;
    }
    .tituloProducto{
        font-size: 24px;
        margin-top: 10px;
    }

}


@media screen and (max-width: 450px) {
 
    .products-list-container .product-item  img{
        width: 165px;
    }

    .product-item {
        font-size: x-small;
    }

}