.updateProfile-div{
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    background: #ffffff;
    margin: 15px;
    border-radius: 5px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 17px;
    align-items: center;
}
.form-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: min-content;
    align-items: center;
    align-self: center;
}

.input-group{
    width: 100%;

}

.form-registro label{
    color: rgba(0, 0, 0, 0.461);
}

