.div-filtros{
    color: white;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 15px;
    flex-wrap: nowrap;
}

.div-filtros div{
  display: flex;
  flex-direction: column;
}

.div-filtros label{
  display: flex;
  flex-direction: row;
  color: white;
  margin: 1px 4px;
  align-items: center;
}


@media screen and (max-width: 650px) {

    .div-filtros{
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        font-size: 15px;
        color:white;
    }

  .div-filtros label{
    display: flex;
    flex-direction: column;
    color:darkred;
    margin: 1px 4px;
  }
  }