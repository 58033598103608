.delete-opt{
    margin: 20px 0 0 0;

}

.img-anterior{
    margin: 20px 0;
 border: 1px solid white;
 border-radius: 5px;
}

.div-opt-adic{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid white;
    border-radius: 5px;
    background: #670000;
    margin: 15px;
    border-radius: 10px;
}



.div-opt-adic div{
    margin: 10px;
    padding: 20px;
    border: 1px solid white;
    border-radius: 5px;
    background: linear-gradient(to bottom, white, #b4b4b4);
}