.productDetail {
    display: flex;
    margin: 0 4% 4%;
    border: 2px #670000 solid;
    border-top: none;
    background: #fff;
    border-radius: 0 0 8px 8px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    color: black!important;
}

.divDataOption{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.divDataOption h4{
    margin: 0;
    font-family: "Jost", sans-serif;
}
.divDataOption span{
    font-size: 15px;
    font-family: "Jost", sans-serif;
}

.divDetail {
    width: 100%;
    padding: 50px 50px 30px;
    margin-bottom: 30px;
    background: linear-gradient(to top, #a70000, #670000);
}

.divBack {
    display: flex;
    justify-content: flex-start;
    width: 92%;
}

.btnBack {
    background: linear-gradient(to bottom, #f2dde2, #FFB6C1);
    border: 1px solid pink;
    border-radius: 0px 0px 6px 6px;
    padding: 4px;
    font-family: "Jost", sans-serif;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 7px;
    color: rgb(105 8 80);
    transition: all .5s;
    cursor: pointer;
    transition: all .50s ease-in-out;
}

.btnBack:hover {
    background: linear-gradient(to bottom, #f2dde2, #ffd9df);
    color: rgb(94, 14, 26);
}

.prodName{
    margin: 0 4%;
    background: darkred;
    color: white;
    padding: 10px 0;
    border: 0.5px solid pink;
    border-radius: 14px 13px 0 0;
}
.name {
    font-family: "Jost", sans-serif;
    margin: 0;
}

.comentVendidos{
    font-size: smaller; 
    color: #aeaeae;
    font-family: "Jost", sans-serif;}

.dataProd{
    width: 100%;
}

.divDatosProd {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 2
}

.productDetailImg {
    width: 30%;
    align-self: center;
    border-radius: 14px;
    margin-bottom: 0px;
    transition: width .3s ease-out;
}

.productDetailImgActive {
    width: 60%;
    height: auto;
    border-radius: 7px;
    transition: width .6s ease-in;
}


.divBtn {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
}

.btnIrCarrito:hover {
    background: rgb(134, 6, 55);
    transition: all 0.5s;
}

.btnSeguir {
    font-family: "Jost", sans-serif;
    margin: 3%;
    color: white;
    background: rgb(75, 62, 255);
    border-radius: 5px;
    border: 1.5px blue solid;
    padding: 5px 20px;
    width: max-content;
}

.btnSeguir:hover {
    background: rgb(17, 17, 184);
    transition: all 0.5s;
}

.ulCategory {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
}

.descrProd {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px 0 20px 0;
    border: 1px pink solid;
    border-radius: 2px;
    place-content: center;
    margin: 0;
    background-color: #670000;
    color: white;
    transition: border .5s;
    font-family: "Jost", sans-serif;
}

.categories {
    font-family: "Jost", sans-serif;
    margin: 0 0 10px 0;
}

.descrProd:hover {
    border: 1.8px rgb(77, 12, 12) solid;
    background-color: #670000;
}

.divCategory {
    padding: 0 20px 0 20px;
    display: flex;
    flex-direction: row;
    border: 1px pink solid;
    border-top: none;
    border-radius: 2px;
    place-content: center;
    transition: border .5s;
    font-family: "Jost", sans-serif;
    justify-content: center;
    width: 100%;
}


.aviso {
    font-family: 'Lato', sans-serif;
}



@media screen and (max-width: 855px) {

    .divDatosProd {
        width: 100%;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
    }
    .divDetail {
        padding: 50px 0px 30px;
    }
 
}

@media screen and (max-width: 800px) {
    .productDetailImg {
        width: 250px;
        transition: width .6s ease-in;

    }

    .productDetailImgActive {
        width: 260px;
        border-radius: 15px;
        transition: width .6s ease-in;
    }

    .btnIrCarrito {

        padding: 3px 7%;
        width: max-content;
    }

    .btnSeguir {

        padding: 3px 5%;
        width: max-content;
    }

    .divDatosProd {
        width: 100%;
    }
    .divDataOption span{
        font-size: 14px;
    }

}

@media screen and (max-width: 800px) {
    .productDetailImg {
        width: 50px;
    }

    .productDetailImgActive {
        width: 110px;
        border-radius: 7px;
        transition: width .6s ease-in;
    
    }
}