.foot {
  display: flex;
  flex-direction: row;
  padding-top: 28px;
  color: black;
  background: linear-gradient(180deg, rgb(255, 255, 255) 80%, rgb(250, 250, 250));
  font-family: "Jost", sans-serif;
  border-top: 0.5px #6b010142 solid;

  }
  
  .linkFot {
    margin: 4px;
    border-right: 1px solid silver;
    flex: 1;
  }
  .linkFot .webAflorar {
    font-size: x-large;
    color: darkred;

  }
  
  .mediosDePago {
    margin: 4px;
    border-right: 1px solid silver;
    flex: 1;
  }
  
  
  .contacto {
    margin: 4px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid silver;
    flex: 1;
  }
  
  .contactoTel {
    font-size: large;
    text-decoration: none;
    color: darkred;
  }
  
  .contactoMail {
    font-size: large;
    text-decoration: none;
    color: darkred;

  }
  
  .fa-phone {
    margin-right: 2%;
    color: black;
    font-size: x-large;
  }
  
  .fa-envelope {
    margin-right: 4%;
    color: darkred;
    font-size: x-large;
  }
  
  .socialBarFooter {
    margin: 4px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .instFoot {
    font-size: x-large;
    color: darkred;
    text-decoration: none;
  }
  
  .fa-instagram {
    margin-right: 2%;
    color: darkred;
    font-size: x-large;
  }
  
  .fbFoot {
    font-size: x-large;
    color: darkred;
    text-decoration: none;
  }



  .legals{
    flex: 2;
    text-align: center;
    background: #670000;
    font-size: small;
    border-top: 1px solid silver;
    padding-bottom: 10px;
    color: white;
  }
  .mail{
    text-decoration: none;
    color:white

  }
  
.dark-mode {
  color: white;
}

.dark-mode a {
  color: silver;
}

.foot.dark-mode{
  background: #670000!important;
}


  
  @media screen and (max-width: 650px) {

    .foot {
      display: flex;
      grid-column-gap: .5%;
      padding-top: 28px;
      color: darkred;
      background: linear-gradient(180deg, rgba(251, 249, 249, 0.91), transparent);
      font-family: "Jost", sans-serif;
      flex-direction: column;
      font-size: medium;
      }

      .foot p {
        font-size: small;
        }

        
  .linkFot {
    margin: 4px;
    border-top: 1px solid rgb(255, 255, 255);
    border-right: none;
  }
  .mediosDePago {
    margin: 4px;
    border-top: 1px solid rgb(255, 255, 255);
    border-right: none;

  }
  .contacto {
    margin: 4px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgb(255, 255, 255);
    border-right: none;
    
  }
  .socialBarFooter {
    border-top: 1px solid rgb(255, 255, 255);
    
  }

  }