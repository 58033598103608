.linkMiddleImages {
    color: white;
    text-decoration: none;
}

.imagenButton {
    -webkit-background-size: cover;
    background-size: cover; /* Estándar */
    background-position-x: center 40%;
    transition: 'background-position-x 1s ease-in-out';

}

.imagenButton:hover {
    background-position-x: right;

}

.topProductsDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 35px 10px;
}