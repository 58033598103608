.categories{
 display:flex;
 flex-direction: column;   
}

.navBarSeccions {
    display: flex;
    margin: 0%;
    align-items: center;
    width: 100%;
    border-top: 1px solid #a00303;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 3 ;
    z-index: 1000;
  }
  .navBarSeccions.dark-mode{
    background: #420000!important;
    border-top: 1px solid white!important;
  }

  .imgNavBar {
    width: 150px;
    margin: 5px;
    margin-top: 10px;
  }
  

  .categories-navigation{
    display: flex;
    margin-left: 10px;
    color: rgb(4, 79, 39);
    font-family: "Jost", sans-serif;
    text-decoration: none;
}

.categories-navigation a{
    color: rgb(110, 2, 2);
}
  
  
  @media screen and (max-width: 1000px) {
  
    .navBar {
      align-items: flex-end;
    }
  
  
  
    .navTop {
      display: flex;
      background: linear-gradient(rgb(255 255 255 / 77%), rgb(255 187 187 / 70%));
      ;
      justify-content: space-around;
      text-decoration: none;
      font-size: 50%;
    }
  
    .divContacto {
  
      display: flex;
      flex-direction: row;
  
    }
  

  }
  @media screen and (max-width: 650px) {
    .navBarSeccions {
      display: contents;
    }
  
  
  
  }
  
  
  @media screen and (max-width: 400px) {
    .imgNavBar {
      width: 250px;
    }
  
  
  
  }