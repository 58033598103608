/* .form-buscador-mobile{
} */

.input-buscador-full-navbar-mobile,
.input-buscador-empty-navbar-mobile{
    background: white;
    padding: 10px;
    border-radius: 20px;
    text-transform: uppercase;
    margin-bottom: 15px;
}