/* Carousel  */
.carousel-box{
    background-color: transparent;
    background: transparent;
}


.carousel-container{
    overflow: hidden;
    padding-bottom: 0px;
  }

  .carousel-container.dark-mode{
    background-color: #000;
  }

.carousel-container .thumbs-wrapper{
    display: none !important;
}

.carousel-container .carousel-status{
    display: none !important;

}

.carousel-container img{
    overflow: hidden;
    height: 80vh;
    object-fit: cover;
}

.carousel.carousel-slider{
    height: 85vh;
}

.legend{
    text-transform: uppercase;
    text-shadow: 0px 0px 20px #000, 0px 0px 20px #000, 0px 0px 20px #000, 0px 0px 20px #000;
    font-weight: 500;
    font-family: "Jost", sans-serif;
}

/* Carousel 

.carousel-container {
    padding-top: 0px;
    overflow: hidden;
    padding-bottom: 15px;
    margin: 0 25px;
}

.carousel-container .thumbs-wrapper {
    display: none !important;
}

.carousel-container .carousel-status {
    display: none !important;

}

.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    display: flex;
    width: 100%;
    transition: height .15s ease-in;
    flex-direction: column;
}

.carousel-container img {
    overflow: hidden;
    object-fit: cover;

    height: 80vh;
}

.carousel.carousel-slider {
    border-radius: 5px;
}



.carousel .slide .legend {
    left: 0; 
     margin-left: 0;
    background: none;
    color: #fff;
    font-family: Trebuchet MS,Lucida Sans Unicode,Lucida Grande,Lucida Sans,Arial,sans-serif;
    font-size: 450%;
    opacity: .25;
    padding: 0 3px 4px 0;
    position: absolute;
    text-transform: uppercase;
    transform: scale(.8);
    transition: all .5s ease-in-out;
    width: 100%;
    z-index: 0;
    text-align: center;
}

.carousel .slide .legend:hover {
    opacity: 1;
    z-index: 5;
    border-radius: 10px;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
    transform: scale(1);
}


@media screen and (max-width: 1100px) {
    
    .carousel-container img {
        overflow: hidden;
        height: 70vh;
    }
}
@media screen and (max-width: 900px) {

    .carousel-container {
        margin: 0px;
    }
    
    .carousel-container img {
        overflow: hidden;
        height: 70vh;
    }

    .carousel .slide .legend {
        transition: all .5s ease-in-out;
        font-size: 300%;
        opacity: 0.25;
        z-index: 0;
        transform: scale(0.8);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        transition: opacity 0.5s, z-index 1s, background .30s transform 2s;
        font-family: Trebuchet MS,Lucida Sans Unicode,Lucida Grande,Lucida Sans,Arial,sans-serif;
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
    }

    .carousel .slide .legend:hover {
        opacity: 1;
        z-index: 5;
        border-radius: 10px;
        text-shadow:
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
        transform: scale(0.9);
    }



}

@media screen and (max-width: 600px) {

    .carousel-container img {
        overflow: hidden;
        height: 35vh;
    }
}
@media screen and (max-width: 400px) {
    .carousel-container {
        height: 40vh;
    }

    .carousel-container img {
        overflow: hidden;
        height: 40vh;
    }

    .carousel-slider {
        width: 100%;
        height: 40vh;
    
}
    .carousel .slide .legend {
        top: 0;
        font-size: 180%;
    }

} */