.perfil-usuario{
    color: black;
    background: linear-gradient(to top, #a70000, #670000);
    margin: 50px;
    padding: 20px;
    border-radius: 14px;
}
.perfil-usuario p{
    font-family: "Jost", sans-serif;
    color: white;
    font-weight: 700;
    font-size: large;
    /* color: black; */

}

.perfil-usuario h1{
    font-family: "Jost", sans-serif;
    font-weight: 700;
    margin-top: 15px;
    color: black;
    padding: 10px 0;
    background:white;
    border-radius: 10px;
}

.perfil-usuario h2{
    font-family: "Jost", sans-serif;
    font-weight: 700;
    margin-top: 15px;
    color: rgb(255, 255, 255);
    padding: 10px 0;
    border-bottom: 1px solid rgba(116, 116, 116, 0.588);

}

.perfil-usuario strong{

    font-weight: 700;
    font-family: "Jost", sans-serif;
    color: darkred;
} 

.div-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 11px 200px 50px ;
    border-radius: 10px;
}

.div-btns div{
    margin: 5px;
    font-family: "Jost", sans-serif;
    color: darkred;
    border: 1px solid rgb(143, 0, 0);
    padding: 2%;
    border-radius: 5px;
    flex: 1;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all .25s ease-out;
}

.div-btns div:hover{
  background: rgb(246, 246, 246);
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

/* .div-btns button{
    margin: 5px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    border: 1px solid white;
    padding: 2%;
    border-radius: 5px;
    transition: all .25s ease-out;
    background: linear-gradient(to bottom, #FFD1DC, #FFB6C1);
} */

@media screen and (max-width: 550px) {
    .div-btns{
        padding: 0;
    }
    .perfil-usuario{
       padding: 10px;
        margin: 0;
    }


}